/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * Zearch is a functional React component that renders the search functionality,
 * filters, and item listing.
 *
 * @author Yang Ming, Joshua Soke
 * @version May 09, 2023
 */

import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useQuery } from '@tanstack/react-query'
import GiftCardList from './components/GiftCardList'
import GiftCardsFilter from './components/GiftCardsFilter'
import Loading from '@components/Loading'
import { getGiftCards } from '@services/api.business.service'

export default function GiftCards() {
  const isMobile = useSelector(state => state.content.isMobile)
  const location = useSelector(state => state.user.location)

  const [filter, setFilter] = React.useState({
    title: '',
    types: [],
    business_id: '',
  })

  const { isPending, error, data } = useQuery({
    queryKey: ['getGiftCards', filter],
    queryFn: () =>
      getGiftCards({
        business_id: filter?.business_id,
        title: filter?.title,
        lat: location?.lat,
        lng: location?.lng,
        types: filter?.types,
      }),
  })

  //If the query is pending, return a loading component
  if (isPending) return <Loading />
  // If there is an error, return an error message
  if (error) return 'GiftCardList: An error has occurred: ' + error.message

  const gitCardList = data?.data || []
  console.log('GiftCardList', gitCardList)

  return (
    <div className='content-wraper hide-scroll w-screen text-left font-lexend md:flex'>
      <GiftCardsFilter isMobile={isMobile} setFilter={setFilter} filter={filter} />
      <div className='w-full'>
        <GiftCardList gitCardList={gitCardList} />
      </div>
    </div>
  )
}
