import React from 'react'
import { Link } from 'react-router-dom'

export default function GiftGuideSection() {
    return (
        <section className='w-full px-3 py-7'>
            <div className='relative w-full bg-[#F0A3A3] rounded-full h-[63px] '>
                <Link to="/cochrane-ab/holiday-gift-guide">
                    <div className='absolute top-[-5px] left-[-3px] w-full bg-[#520A0A] rounded-full h-[63px] flex items-center justify-center gap-3'>
                        <h3 className='text-[16px] font-light text-[#FAF5EA]'>browse OUR LOCAL <strong>GIFT GUIDE</strong></h3>
                        <img src="/gift-guide/arrow.png" alt="Gift Guide" />
                    </div>
                </Link>
            </div>
        </section>
    )
}
