import React from 'react'

export default function Logo({
  logo,
  name,
  storeLink
}) {
  return (
    <a href={storeLink} target='_blank' rel='noreferrer'>
      <img
        src={logo}
        alt={name}
        className='flex h-[120px] w-[120px] smsize:w-[100px] object-contain smsize:h-[100px] items-center justify-center rounded-full border-[2px] bg-[#fffdf6]'
      />
    </a>
  )
}
