import React from 'react'

export default function SeeMoreDetails({
    item_id
}) {
    return (
        <a className='uppercase text-[#8f7678] underline smsize:text-[14px]'
            href={`${window.location.origin}/item/${item_id}?ref=gift-guide`}
            target='_blank'
            rel='noreferrer'
        >
            See more details
        </a>
    )
}
