import React, { useEffect } from 'react'
import { getRecommendedList } from '@services/api.recommended.service'
import { useQuery } from '@tanstack/react-query'
import Loading from '@components/Loading'
import CollectionCard from './components/CollectionCard'
import ShowMore from './components/ShowMore'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { setSearchTitle } from 'reducers/contentReducer'

import CollectionDetail from './CollectionDetail'
export default function CollectionList({
    location,
    dispatch,
}) {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [currentCollection, setCurrentCollection] = React.useState(null)

    const {
        isPending,
        error,
        data,
    } = useQuery({
        queryKey: ['recommendedList'],
        queryFn: getRecommendedList,
    })

    //If the query is pending, return a loading component
    if (isPending) return <Loading />
    // If there is an error, return an error message
    if (error) return 'Recommend Section: An error has occurred: ' + error.message

    const recommendedList = data?.data || []
    console.log('RecommendedList', recommendedList)

    // const collection_id = searchParams.get('id')
    // console.log('collection_id', collection_id)
    // let collection = null
    // if (collection_id) {
    //     collection = recommendedList.find(item => item._id === collection_id)
    // }

    const onClickSeemore = () => {
        console.log('onClickSeemore')
    }

    return (
        <div className='flex gap-3 flex-wrap'>
            <div className='w-full flex flex-col'>
                {/* <StoreListFilterSelected /> */}
                {currentCollection ? <CollectionDetail
                    collection={currentCollection}
                    setCollection={setCurrentCollection} /> :
                    <div className='flex px-10 py-8 gap-7 flex-col smsize:px-5 smsize:py-5'>
                        <h1 className='text-[18px] font-normal uppercase'>Curated collections</h1>
                        <div className='flex gap-3 flex-wrap px-5 smsize:px-0 smsize:justify-center smsize:items-center smsize:gap-10'>
                            {recommendedList?.map((item) => {
                                return <CollectionCard
                                    key={item?._id}
                                    item={item}
                                    dispatch={dispatch}
                                    setCurrentCollection={setCurrentCollection}
                                />
                            })}
                        </div>
                        {recommendedList?.length > 0 && recommendedList.currentPage < recommendedList.totalPage &&
                            <div className='text-center'>
                                <ShowMore onClick={onClickSeemore} />
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    )
}
