/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * This module exports a React component named 'ReservePopup'. This component displays a popup for reserving an item.
 * It includes a form for submitting the reservation, and displays a success message upon completion.
 * 
 * The component takes four props: 'item', 'opened', 'setOpened', and 'userinfo'. 'item' is the item being reserved.
 * 'opened' is a boolean indicating whether the popup is open or not. 'setOpened' is a function to set the 'opened' state.
 * 'userinfo' is an object containing information about the user.
 * 
 * The component uses several hooks for state and side effects. It uses 'useSelector' to access the redux store, 'useState' to manage local state,
 * 'useEffect' to handle side effects, and 'useRef' to reference DOM elements.
 * 
 * @author Yang Ming, James Nguyen
 * @version January 18nd, 2024
 */

import { message, Form } from 'antd'
import React, { useEffect, useState } from 'react'
import { makeCartReservation } from '@services/api.reservation.service'
// import { getQuantity } from '@services/api.service'
// import { useSelector } from 'react-redux'
import CommonModal from '@components/CommonModal'
import ReserveCompleted from './ReserveCompleted'
import ReserveForm from './ReserveForm'

/**
 * A component that displays a popup for reserving an item.
 * 
 * @param {Object} props - The props object.
 * @param {Object} props.item - The item being reserved.
 * @param {boolean} props.opened - A boolean indicating whether the popup is open or not.
 * @param {Function} props.setOpened - A function to set the 'opened' state.
 * @param {Object} props.userinfo - An object containing information about the user.
 * @returns {JSX.Element} The 'ReservePopup' component.
 */
export default function ReservePopup({
  item,
  opened,
  setOpened,
  userinfo,
  selectedVariant
}) {
  const [completed, setCompleted] = useState(false)
  const [error, setError] = useState('')
  const [form] = Form.useForm()

  let price = selectedVariant?.price || item?.salePrice
  let quantity = selectedVariant?.quantity || item?.quantity

  useEffect(() => {
    setError('')
    setCompleted(false)

    if (form) {
      form.resetFields()
      form.setFieldsValue({
        amount: 1,
        username: userinfo?.firstName || '',
        userEmail: userinfo?.username || '',
      })
    }

  }, [form, userinfo, opened, selectedVariant])

  const handleSubmit = async values => {
    try {
      // check quantity
      if (values.amount > quantity) {
        setError('The available quantity is ' + quantity)
        return
      }
      // itemId, businessId, username, userEmail, amount
      await makeCartReservation({
        businessId: item?.business?._id,
        user_id: userinfo?._id,
        tmp_id: localStorage.getItem('tmp_id'),
        username: values?.username,
        userEmail: values?.userEmail,
        items: [
          {
            item_id: item?._id,
            quantity: values.amount,
            // variant_id: selectedVariant?._id,
            variant_id: selectedVariant?.variant_id,
            variant_title: selectedVariant?.title,
            price: price,
          }
        ]
      })
      message.success('Your reservation has been made')
      setCompleted(true)
    } catch (error) {
      message.error('Error making reservation: ' + error?.data?.message)
      setError(error?.data?.message)
    }
  }


  function closePopup() {
    setOpened(false)
  }


  return (
    <CommonModal
      isOpen={opened}
      setIsOpen={setOpened}
      width={'600px'}
      children={
        <div className='flex h-fit w-full flex-col items-center justify-center rounded-3xl bg-fitzba-white-static font-lexend drop-shadow-2xl sm:w-[500px] lg:w-[600px]'>
          <div className={`flex h-[120px] w-full items-center justify-center rounded-t-3xl bg-black`}>
            <img
              src='/fitzba-ICON-WORD-WHITE.png'
              alt='logo'
              className='h-16 '
            />
            <div className='absolute top-4 right-5 text-xl text-[#a6a6a6] hover:text-fitzba-bright-gold-static'>
              <button onClick={closePopup}>
                x
              </button>
            </div>
          </div>
          <div className='w-full px-[10px]'>
            {completed ? (
              <ReserveCompleted closePopup={closePopup} />
            )
              :
              <ReserveForm
                item={item}
                form={form}
                userinfo={userinfo}
                selectedVariant={selectedVariant}
                handleSubmit={handleSubmit}
                error={error}
                setError={setError}
              />
            }
          </div>
        </div>
      }
    />
  )
}