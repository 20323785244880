import React from 'react'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

export default function ItemImage({
  image,
  name,
  width,
  height,
  extraCss
}) {
  return (
    <Zoom>
      <img
        src={image}
        alt={name}
        className={`w-[${width}] h-[${height}] object-cover ${extraCss}`}
        draggable="false"
        // style={{ touchAction: 'none' }}
      />
    </Zoom>
  )
}
