import React, { useEffect } from 'react'
import './GiftGuide.css'
import GiftGuide1a from './GiftGuide1a'
import GiftGuide1b from './GiftGuide1b'
import GiftGuide2a from './GiftGuide2a'
import GiftGuide2b from './GiftGuide2b'
import GiftGuide3a from './GiftGuide3a'
import GiftGuide3b from './GiftGuide3b'
import GiftGuide3c from './GiftGuide3c'
import GiftGuide3d from './GiftGuide3d'
import { getGiftGuide } from '@services/api.business.service'

export default function GiftGuideItem({
  store,
  currentPage,
  totalPage,
  handlePrevious,
  handleNext,
  selectItems,
  index,
  setIndex,
}) {
  const [data, setData] = React.useState(null)

  const tempList = [
    {
      id: '1a',
      name: '1a',
      content: (
        <GiftGuide1a
          business={data}
          currentPage={currentPage}
          totalPage={totalPage}
          handlePrevious={handlePrevious}
          handleNext={handleNext}
          selectItems={selectItems}
          index={index}
          setIndex={setIndex}
        />
      ),
    },
    {
      id: '1b',
      name: '1b',
      content: (
        <GiftGuide1b
          business={data}
          currentPage={currentPage}
          totalPage={totalPage}
          handlePrevious={handlePrevious}
          handleNext={handleNext}
          selectItems={selectItems}
          index={index}
          setIndex={setIndex}
        />
      ),
    },
    {
      id: '2a',
      name: '2a',
      content: (
        <GiftGuide2a
          business={data}
          currentPage={currentPage}
          totalPage={totalPage}
          handlePrevious={handlePrevious}
          handleNext={handleNext}
          selectItems={selectItems}
          index={index}
          setIndex={setIndex}
        />
      ),
    },
    {
      id: '2b',
      name: '2b',
      content: (
        <GiftGuide2b
          business={data}
          currentPage={currentPage}
          totalPage={totalPage}
          handlePrevious={handlePrevious}
          handleNext={handleNext}
          selectItems={selectItems}
          index={index}
          setIndex={setIndex}
        />
      ),
    },
    {
      id: '3a',
      name: '3a',
      content: (
        <GiftGuide3a
          business={data}
          currentPage={currentPage}
          totalPage={totalPage}
          handlePrevious={handlePrevious}
          handleNext={handleNext}
          selectItems={selectItems}
          index={index}
          setIndex={setIndex}
        />
      ),
    },
    {
      id: '3b',
      name: '3b',
      content: (
        <GiftGuide3b
          business={data}
          currentPage={currentPage}
          totalPage={totalPage}
          handlePrevious={handlePrevious}
          handleNext={handleNext}
          selectItems={selectItems}
          index={index}
          setIndex={setIndex}
        />
      ),
    },
    {
      id: '3c',
      name: '3c',
      content: (
        <GiftGuide3c
          business={data}
          currentPage={currentPage}
          totalPage={totalPage}
          handlePrevious={handlePrevious}
          handleNext={handleNext}
          selectItems={selectItems}
          index={index}
          setIndex={setIndex}
        />
      ),
    },
    {
      id: '3d',
      name: '3d',
      content: (
        <GiftGuide3d
          business={data}
          currentPage={currentPage}
          totalPage={totalPage}
          handlePrevious={handlePrevious}
          handleNext={handleNext}
          selectItems={selectItems}
          index={index}
          setIndex={setIndex}
        />
      ),
    },
  ]

  useEffect(() => {
    if (!store) return
    async function fetchData() {
      try {
        const result = await getGiftGuide({
          business_id: store?._id,
        })
        console.log('GiftGuideItem', result)
        if (result?.status !== 'success') return
        setData(result?.data)
      } catch (err) {
        console.log(err.data?.message || 'get gift guide item error')
      }
    }
    fetchData()
  }, [store])

  if (!data) return null

  return (
    <>
      {
        // tempList?.find(item => item.id === ('3a'))
        tempList?.find(item => item.id === (store?.giftGuideTemplate || '1a'))
          ?.content
      }
    </>
  )
}
