import React from 'react'

export default function PageNumbers({
    currentPage,
    totalPage,
    pageNumberRef
}) {
    return null
    // return (
    //     <div ref={pageNumberRef} className='absolute top-10 right-5 rounded-full bg-black px-5 py-1 text-[20px] text-white opacity-25'>
    //         {currentPage}{totalPage && `/${totalPage}`}
    //     </div>
    // )
}
