/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * The CategoriesHeader component displays a list of categories in a horizontal scrollable header.
 * Each category is clickable and redirects to a search page filtered by the selected category.
 *
 * @author James Nguyen, Yang Ming
 * @version November 10th, 2023
 */

import { getGlobalCategoryTopHeaderListNew } from '@services/api.globalCategory.service'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { setFilterCategoryList } from '@reducers/contentReducer'
import { Dropdown } from 'antd'
import { setNewCategoryList } from 'reducers/contentReducer'
// const categories = [
//   'Clothing',
//   'Baby',
//   'Beauty & Personal Care',
//   'Home',
//   'Sports & Fitness',
//   'Pets',
// ]

/**
 * CategoriesHeader displays a list of categories in a horizontal scrollable header.
 * 
 * @returns {JSX.Element} The JSX code for the CategoriesHeader component.
 */

export default function CategoriesHeaderNew() {

  const {
    // isPending,
    // error,
    data: topHeaderList,
  } = useQuery({
    queryKey: ['topHeaderList'],
    queryFn: async () => {
      const res = await getGlobalCategoryTopHeaderListNew()
      dispatch(setNewCategoryList(res?.data || []))
      return res
    }
  })

  const navigate = useNavigate()
  const dispatch = useDispatch()

  /**
    * Handles the click event on a category.
    * Dispatches an action to update the filter category list in the Redux store,
    * and navigates to the search page with the category ID as a query parameter.
    * 
    * @param {string} id - The ID of the clicked category.
 */
  function onClickCategories(id) {
    console.log('onClickCategories', id)
    dispatch(setFilterCategoryList([id]))
    navigate(`/search?categories=${id}`)
  }

  return (

    // sticky
    <div className='flex h-[50px] phone:h-[45px] w-full flex-row items-center justify-start overflow-x-auto gap-3 phone:gap-[40px] overflow-y-hidden bg-[#454545] px-[20px] phone:justify-center border-t-2 border-[#C8C8C8]'>
      <span
        className='cursor-pointer font-bold whitespace-nowrap h-full flex items-center justify-center text-white hover:text-[#f3f3f3] px-5 sm:px-2 text-[13px] font-lexend color-black hover:scale-105 transition-transform duration-150 ease-in-out'
        key={'holiday-gift-guide'}
        onClick={() => {
          navigate('/cochrane-ab/holiday-gift-guide')
        }}
      >
        Gift Guide
      </span>
      <span
        className='cursor-pointer whitespace-nowrap h-full flex items-center justify-center text-white hover:text-[#f3f3f3] px-5 sm:px-2 font-light text-[13px] font-lexend color-black hover:scale-105 transition-transform duration-150 ease-in-out'
        key={'gift-cards'}
        onClick={() => {
          navigate('/gift-cards')
        }}
      >
        Gift Cards
      </span>


      {topHeaderList?.data?.map((category) => (
        <Dropdown
          key={category?._id}
          overlayStyle={{
            textAlign: 'left',
          }}
          menu={{
            items: category?.children?.map((item) => ({
              key: item?._id,
              label: (
                <span onClick={() => onClickCategories(item?._id)}>
                  {item?.title}
                </span>
              ),
            }))
          }}
        >
          <span
            className='cursor-pointer whitespace-nowrap h-full flex items-center justify-center text-white hover:text-[#f3f3f3] px-5 sm:px-2 font-light text-[13px] font-lexend color-black hover:scale-105 transition-transform duration-150 ease-in-out'
            key={category?._id}
            onClick={() => {
              // if (category?.children?.length > 0) {
              //   return
              // }
              onClickCategories(category?._id)
            }}
          >
            {category?.title}
          </span>
        </Dropdown>
      ))}
    </div>
  )
}

