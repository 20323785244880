import React from 'react'
import { Link } from 'react-router-dom'
import { formatCurrency } from '@utils/Helper'

export default function OrderItemCard({
    item
}) {
    return (
        <div className='w-full flex justify-center gap-3 p-5'>
            <div className='flex items-center justify-centerw-[160px] h-[160px] border border-[#D9D9D9] rounded-lg'>
                <img className='w-[155px] h-[155px]' src={item?.image} alt={item?.name} />
            </div>
            <div className='w-full flex flex-col justify-between'>
                <div className='w-full flex flex-col'>
                    <div className='flex items-center justify-between'>
                        <h4 className='text-[18px] font-light m-0 p-0'>{item?.name}</h4>
                        <span className='text-[18px] font-light'>{formatCurrency(item?.subTotal)}</span>
                    </div>
                    <div className='flex items-center justify-between'>
                        {item?.variant_title &&
                            <span className='text-[14px] font-light text-[#A6A6A6]'>{item?.variant_title}</span>
                        }
                        {item?.quantity > 1 &&
                            <span className='text-[13px] font-light'>{formatCurrency(item?.price)}/ea</span>
                        }
                    </div>
                    <div className='mt-3 flex gap-3'>
                        <div className='border border-black rounded-full w-[60px] text-[12px] font-light px-2 py-1'>Qty: {item?.quantity}</div>
                        {/* <div className='border border-black rounded-full w-[120px] text-[12px] font-light px-2 py-1'>Size: small</div> */}
                    </div>
                </div>
                <div className='w-full flex items-center justify-between smsize:flex-col smsize:items-start smsize:gap-3'>
                    <a className='text-[#0B6186] font-[13px] underline' href={`/item/${item?.item_id}`} target="_blank" rel="noreferrer">
                        View item
                    </a>
                    <button className='flex items-center justify-center rounded-full border border-[#999999] px-5 py-1 text-[13px] uppercase font-light'>
                        leave a review
                    </button>
                </div>
            </div>
        </div>
    )
}
