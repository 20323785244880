import React from 'react'
import { IoLocationSharp } from 'react-icons/io5'

export default function Address({
  business
}) {
  return (
    <div className='w-full flex items-center justify-start gap-3'>
      <div className='flex h-[25px] w-[25px] items-center justify-center rounded-full bg-[#afafaf] text-white'>
        <IoLocationSharp className='h-[25px] w-[25px]' />
      </div>
      <p className='w-full text-[#202020] underline smsize:text-[14px]'>
        {`${business?.address}, ${business?.city}, ${business?.province} ${business?.postalCode}`}
      </p>
    </div>
  )
}
