import React from 'react'
import GiftCardsItem from './GiftCardsItem'

export default function GiftCardList({
    gitCardList,
}) {

    return (
        <div className='flex gap-3 flex-wrap'>
            <div className='w-full flex flex-col'>
                <div className='flex px-10 py-8 gap-7 flex-col smsize:px-5 smsize:py-5'>
                    <h1 className='text-[18px] font-normal uppercase'>Gift Cards</h1>
                    <div className='w-full flex gap-3 flex-wrap px-5 smsize:px-0 smsize:justify-center smsize:items-center smsize:gap-5'>
                        {gitCardList?.length === 0 && <div className='text-[15px] text-[#666]'>No gift cards found</div>}
                        {gitCardList?.map((item) => {
                            return <GiftCardsItem
                                key={item?._id}
                                item={item}
                            />
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
