import React from 'react'
import { Dropdown } from 'antd'
import { BarsOutlined } from '@ant-design/icons'

export default function DropDown({
    selectItems,
    index,
    setIndex
}) {
    // return null
    if (!selectItems || selectItems.length === 0 ) return null
    return (
        <Dropdown
            onOpenChange={(e)=>{
                console.log('e', e)
            }}
            menu={{
                items: selectItems,
            }}
            trigger={['click']}
            placement='left'
            arrow
        >
            <div className='z-20 absolute cursor-pointer top-2 right-1 text-[30px] flex h-[40px] w-[40px] items-center justify-center rounded-full bg-[#222222] text-white'>
                <BarsOutlined />
            </div>
        </Dropdown>
    )
}
