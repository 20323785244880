import React from 'react'
import { BsFillTelephoneFill } from 'react-icons/bs'

export default function PhoneNumber({
  business
}) {
  return (
    <div className='flex items-center justify-start gap-3'>
      <div className='flex h-[25px] w-[25px] items-center justify-center rounded-full bg-[#afafaf] text-white'>
        <BsFillTelephoneFill />
      </div>
      <a className='text-[#202020] underline smsize:text-[14px]' href={`tel:${business?.businessPhone}`}>
        {business?.businessPhone}
      </a>
    </div>
  )
}
