import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import { IoIosSearch } from 'react-icons/io'
import { Input } from 'antd'
import { getPurchases } from '@services/api.user.service'

import PurchasesEmpty from './components/PurchasesEmpty'
import PurchasesCard from './components/PurchasesCard'

export default function Reservations() {
  const dispatch = useDispatch()
  const userinfo = useSelector(state => state.user.userinfo)
  const navigate = useNavigate()
  // if (!userinfo) {
  //   navigate('/')
  // }
  const [purchases, setPurchases] = React.useState([])
  const [input, setInput] = React.useState('')

  useEffect(() => {
    const fetchData = async () => {
      const res = await getPurchases()
      console.log(res)
      setPurchases(res.data)
    }
    fetchData()
  }, [])

  if (!purchases || purchases.length === 0) {
    return <PurchasesEmpty title='reservations' />
  }

  return (
    <div className='my-6 flex flex-col bg-white smsize:p-3'>
      <div className='m-auto w-full max-w-[1024px] flex flex-col gap-5 smsize:w-full'>
        <h1 className='uppercase text-left text-[30px] smsize:text-[20px]'>your reservations</h1>
        <div className='w-full flex'>
          <div className='w-full flex-[3] flex flex-col gap-5'>
            <div className=' flex items-center justify-between smsize:flex-col smsize:gap-2 smsize:items-start'>
              <div>
                <Input
                  value={input}
                  allowClear
                  prefix={<IoIosSearch size={24} />}
                  className=' rounded-full w-[400px] border-[#A6A6A6]'
                  placeholder='Search your orders'
                  onChange={(e) => setInput(e.target.value)}
                  onKeyDown={() => {
                    console.log('onKeyDown:', input)
                  }}
                />
              </div>
              <button className='rounded-full border border-black px-3 py-1 text-[13px]'>
                Reservations placed in: past 30 days
              </button>
            </div>
            <div className='flex flex-col gap-5'>
              {purchases.map((item, index) => (
                <PurchasesCard key={index} item={item} />
              ))}
            </div>
          </div>
          {/* <div className='flex-1 mt-12'>
            <div>
              <h3>Items for you</h3>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}
