/**
 * Copyright Fitzba Technologies Inc. 2023
 * The NearProducts component fetches and displays a list of products that are near the user's location.
 * 
 * Each product is displayed as an Item component.
 * 
 * The component uses the useQuery hook from react-query for data fetching
 * and the useSelector hook from react-redux to get the user's location and information.
 * 
 * The component also provides a horizontal scrolling feature for the list of products.
 *
 * @author Yang Ming
 * @version June 5th, 2023
 */


import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ShoppingCartHook from '@hooks/ShoppingCart.hook'
import { getFavoriteItems } from '@services/api.user.service'
import LoadingSection from '@components/LoadingSection'
import { useQuery } from '@tanstack/react-query'
import ShowMore from '../../zearch/components/ShowMore'
import ItemCard from './FavoriteItemCard'
import ItemCardMobile from './FavoriteItemCardMobile'
// import { IoIosSearch } from 'react-icons/io'
import { Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import FavouriteButton from './FavoriteButton'
import NoFound from './NoFound'
/**
 * NearProducts fetches and displays a list of products that are near the user's location.
 * 
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.setIsShowRecommended - The function to set the visibility of recommended products (no longer used).
 * @param {Array} props.searchHistory - The user's search history.
 * @returns {JSX.Element} The JSX code for the NearProducts component.
 */
export default function FavoriteItems({
    business,
    isMobile
}) {

    const navigate = useNavigate()
    // const dispatch = useDispatch()
    const location = useSelector(state => state.user.location)
    const userinfo = useSelector(state => state.user.userinfo)
    // const [search, setSearch] = React.useState('')
    const [input, setInput] = React.useState('')
    // const [sortBy, setSortBy] = React.useState('latest')
    // const [currentPage, setCurrentPage] = React.useState(1)
    const [dataList, setDataList] = React.useState(null)
    const [filter, setFilter] = React.useState({
        title: '',
        sortBy: 'latest',
        currentPage: 1
    })

    const { addToCart } = ShoppingCartHook()
    const productListRef = React.useRef(null)

    const {
        isPending,
        // error,
        data,
    } = useQuery({
        queryKey: ['getFavoriteItems', 'list', location?.lat, location?.lng, filter, userinfo.favoriteItems],
        queryFn: async () => {
            const params = {
                lat: location?.lat,
                lng: location?.lng,
                type: 'list',
                ...filter
            }
            console.log('params1', params)
            const res = await getFavoriteItems(params)
            console.log('res', res)
            if (res.currentPage === 1) {
                setDataList(res)
            } else {
                setDataList({
                    ...res,
                    data: [...dataList.data, ...res.data]
                })
            }
            return res
        },
    })

    const updateFilter = (key, value) => {
        let params = {
            ...filter,
            [key]: value
        }
        if (key !== 'currentPage') {
            params.currentPage = 1
        }
        setFilter(params)
        console.log('params', params)
    }

    const onClickShowmore = () => {
        console.log('onClickShowmore')
        // setCurrentPage(currentPage + 1)
        updateFilter('currentPage', filter.currentPage + 1)
    }

    if (isPending)
        return <LoadingSection text='Loading items' height='200px' />

    const items = data || null

    return (
        <section id="favorite-item-list" className="w-full max-w-[1440px] relative m-auto flex flex-col gap-5 pb-5">
            <div className='flex justify-between items-center'>
                <h4 className='uppercase text-[25px] font-normal text-left'>
                    My Items <span className='text-[20px] text-[#727272]'>({items?.total || 0})</span>
                </h4>
                <FavouriteButton
                    text="Explore Items"
                    onClick={() => navigate('/search')}
                />
            </div>
            {items?.total === 0 ?
                <NoFound text="No favorite items found" btnText="Explore Items" btnClick={() => navigate('/search')} /> :
                <>
                    <div className='flex justify-between items-center font-light'>
                        <Input
                            allowClear
                            className='rounded-full max-w-[500px]'
                            placeholder="Search your favourites"
                            prefix={<SearchOutlined />}
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    console.log('search', e.target.value)
                                    // setSearch(e.target.value)
                                    updateFilter('title', e.target.value)
                                }
                            }} />
                        <div className='smsize:text-[12px] flex items-center justify-between gap-1 border rounded-full px-2 py-1 smsize:p-2'>
                            <h3>Sort by:</h3>
                            <select
                                className='border-none font-light'
                                onChange={(e) => {
                                    updateFilter('sortBy', e.target.value)
                                }}
                                value={filter.sortBy}
                            >
                                <option value="latest">Latest</option>
                                <option value="low-to-high">Low to High</option>
                                <option value="high-to-low">High to Low</option>
                            </select>
                        </div>
                    </div>
                    <div className="w-full flex gap-5 text-left flex-wrap smsize:gap-3" ref={productListRef}>
                        {dataList?.data?.map((item, index) => {
                            if (isMobile)
                                return <ItemCardMobile
                                    key={index}
                                    item={item}
                                    userinfo={userinfo}
                                    handleAddToCart={addToCart}
                                />
                            return <ItemCard key={index} userinfo={userinfo} item={item} handleAddToCart={addToCart} />
                        })}
                    </div>
                    {dataList?.currentPage < dataList?.totalPages &&
                        <div className='mt-5'>
                            <ShowMore onClick={onClickShowmore} />
                        </div>
                    }
                </>
            }
        </section >
    )
}
