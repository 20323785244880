import React, { useState } from 'react'
import { Form, Input, Button,  message, Space } from 'antd'
import { isCreditCardValid, isExpiryDateValid, isCVCValid } from '@utils/Helper'
import { paymentCreditCard, checkRegisterPromoCode } from '@services/api.user.service'
import { useSelector } from 'react-redux'
const BASE_PRICE = 99

export default function RegisterStep3({
    setCurrent,
    isMobile
}) {
    const [note, setNote] = useState('* Your first payment starts DATE = TODAY + 1 MONTH.')
    const userinfo = useSelector(state => state.user.userinfo)
    const business_id = useSelector(state => state.user.business_id)
    const [price, setPrice] = useState(BASE_PRICE)
    const [formRef] = Form.useForm()
    const [promoCode, setPromoCode] = useState('')

    const checkCardNumber = async (_, cardNumber) => {
        // return Promise.resolve()

        if (!cardNumber) {
            return Promise.resolve()
        }
        // return true
        if (!isCreditCardValid(cardNumber)) {
            throw new Error('Card number is invalid')
        }
    }

    const checkExpireDate = async (_, expireDate) => {
        if (!expireDate) {
            return Promise.resolve()
        }
        if (expireDate.length < 4 || expireDate.length > 5) {
            throw new Error('Expire date is invalid')
        }
        if (!isExpiryDateValid(expireDate)) {
            throw new Error('Expire date is invalid')
        }
    }

    const checkCvv = async (_, cvv) => {
        if (!cvv) {
            return Promise.resolve()
        }
        if (!isCVCValid(cvv)) {
            throw new Error('CVV code is invalid')
        }
    }

    const onFinish = async (values) => {
        try {
            if (values.planType !== 'free' && values?.planAmount <= 0) {
                message.error('The amount must be greater than 0')
                return
            }
            console.log('business_id', business_id)
            const res = await paymentCreditCard({
                ...values,
                planType: 'monthly',
                planAmount: price,
                user_id: userinfo._id,
                business_id: business_id,
                isCustom: false,
                promoCode
            })
            console.log('onFinish', res)
            message.success('Launch offer setup successfully!')
            setCurrent(3)
        } catch (err) {
            console.error('err', err)
            message.error(err.data?.message || 'Launch offer failed, please try again')
        }
    }

    const checkPromoCode = async (values) => {
        if (!promoCode) {
            message.error('Please enter a promo code')
            return
        }
        try {
            const res = await checkRegisterPromoCode({
                promoCode
            })
            console.log('checkPromoCode', res)
            if (res?.status !== 'success') {
                setPrice(BASE_PRICE)
                message.error(res?.message || 'Promo code is invalid')
                return
            }
            message.success('Promo code applied successfully')
            if (promoCode === 'FOUNDER50') {
                setNote('* Your first month is free, then $50/month per storefront.')
            } else if (promoCode === 'FOUNDER3FREE') {
                setNote('* Your first 3 months are free, then $99/month per storefront.')
            }
            setPrice(BASE_PRICE - res?.value)
        } catch (err) {
            console.error('err', err)
            message.error(err.data?.message || 'Action failed, please try again')
        }
    }

    return (
        <div className='py-8 m-auto w-[500px] smsize:w-full'>
            <Form
                form={formRef}
                name="createNewStore"
                // style={{
                //     maxWidth: 500,
                //     textAlign: 'left',
                // }}
                className='text-left max-w-[500px] smsize:px-3 smsize:max-w-full'
                onFinish={onFinish}
                layout='vertical'
                autoComplete="off"
            >
                {/* <Form.Item
                    name="planType"
                    label="Plan Type"
                    initialValue={'free'}
                    rules={[
                        {
                            required: true,
                            message: 'Please select a plan type',
                        },
                    ]}
                    onChange={onClickPlanType}
                >
                    <Radio.Group>
                        <Radio.Button value="free">Free Trial</Radio.Button>
                        <Radio.Button value="monthly">Monthly</Radio.Button>
                        <Radio.Button value="yearly">Yearly</Radio.Button>
                    </Radio.Group>
                </Form.Item> */}
                {/* <Space>
                    <Form.Item name="planAmount"
                        label="Amount"
                        rules={[
                            {
                                required: true,
                                message: 'Amount is required',
                            }
                        ]}
                        style={{
                            width: '120px'
                        }}

                    >

                        <InputNumber
                            addonBefore='$'
                            onChange={(e) => {
                                setPlanAmount(e)
                            }}
                            disabled={!isCustomPricing}
                        />
                    </Form.Item>
                    <Checkbox onChange={(e) => {
                        setIsCustomPricing(e.target.checked)
                    }}>
                        I'd like to custom the price
                    </Checkbox>
                </Space> */}
                {/* <Form.Item name="setPayment" label="Setup Payment" valuePropName='checked' >
                    <Switch onChange={(v) => {
                        setIsSetupPayment(v)
                    }}
                        className=' bg-gray-300'
                    />
                </Form.Item> */}
                <div className='mb-2'>
                    <h3 className='mb-2 text-[22px] font-[300]'>STOREFRONT PARTNERZ</h3>
                    <p><span className='text-[30px]'>${price}/month</span>{' '}(per storefront)</p>
                </div>
                <div className='smsize:flex smsize:flex-col'>
                    <h3 className='mb-2 text-[22px] font-[300]'>Discount</h3>
                    <Form.Item
                        name='promoCode'
                        label='Promo code (optional)'
                        className='flex smsize:flex-col'
                    >
                        <Space direction="horizontal">
                            <Input
                                size='large'
                                placeholder='Promo code'
                                allowClear
                                onChange={(e) => {
                                    setPromoCode(e.target.value)
                                }}
                            />
                            <Button
                                size='large'
                                onClick={checkPromoCode}
                            >
                                Apply
                            </Button>
                        </Space>
                    </Form.Item>
                </div>
                <div>
                    <h3 className='mb-2 text-[22px] font-[300]'>Payment method</h3>
                    <Form.Item
                        name='cardName'
                        label='Name on card'
                        rules={[
                            {
                                required: true,
                                message: 'Please input card name',
                            },
                        ]}
                    >
                        <Input
                            size='large'
                            placeholder='Name on card'
                            allowClear
                        />
                    </Form.Item>
                    <Form.Item
                        name='cardNumber'
                        label='Card number'
                        rules={[
                            {
                                validator: checkCardNumber,
                            },
                            {
                                required: true,
                                message: 'Please input card number',
                            },
                        ]}
                    >
                        <Input
                            size='large'
                            placeholder='Card number'
                            suffix={<img src='/images/credit-icon.png' alt='' />}
                            allowClear
                        />
                    </Form.Item>
                    <Form.Item
                        style={{
                            marginBottom: 0,
                        }}
                    >
                        <Form.Item
                            name='cardExpireDate'
                            rules={[
                                {
                                    validator: checkExpireDate,
                                },
                                {
                                    required: true,
                                    message: 'Please input expiry date',
                                },
                            ]}
                            style={{
                                display: 'inline-block',
                                width: 'calc(50% - 8px)',
                                marginRight: '16px',
                            }}
                        >
                            <Input
                                size='large'
                                placeholder='MM/YY'
                                allowClear
                            />
                        </Form.Item>
                        <Form.Item
                            name='cardCvv'
                            rules={[
                                {
                                    validator: checkCvv,
                                },
                                {
                                    required: true,
                                    message: 'Please input cvv',
                                },
                            ]}
                            style={{
                                display: 'inline-block',
                                width: 'calc(50% - 8px)',
                            }}
                        >
                            <Input
                                size='large'
                                placeholder='CVV'
                                allowClear
                            />
                        </Form.Item>
                    </Form.Item>
                    <div classname="text-red-500">{note}</div>
                    <div className='mt-3 flex items-center justify-center gap-1'>
                        <p>Powered by</p>
                        <img
                            width={40}
                            src='/images/payment/stripe-logo.webp'
                            alt='stripe'
                        />
                    </div>
                </div>


                <Form.Item style={{
                    marginBottom: 0,
                }}>
                    <Form.Item label=" " colon={false} style={{
                        display: 'inline-block',
                        // width: 'calc(50% - 8px)',
                        marginRight: '16px',
                    }}>
                        <Button
                            className='flex w-full max-w-[100px] cursor-pointer justify-center rounded-full border-2 border-black bg-white py-2 px-5 text-black hover:bg-slate-200'
                            onClick={() => setCurrent(1)}
                        >
                            Previous
                        </Button>
                    </Form.Item>
                    <Form.Item label=" " colon={false} style={{
                        display: 'inline-block',
                        // width: 'calc(50% - 8px)',
                        marginRight: '16px',
                    }}>
                        <Button
                            className='flex w-full max-w-[100px] cursor-pointer justify-center rounded-full border-2 border-black bg-white py-2 px-5 text-black hover:bg-slate-200'
                            onClick={() => setCurrent(3)}
                        >
                            Skip
                        </Button>
                    </Form.Item>
                    <Form.Item label=" " colon={false} style={{
                        display: 'inline-block',
                    }}>
                        <Button
                            htmlType='submit'
                            className='flex w-full max-w-[100px] cursor-pointer justify-center rounded-full border-2 border-black bg-black py-2 px-5 text-white hover:bg-slate-200'
                        >
                            Continue
                        </Button>
                    </Form.Item>
                </Form.Item>
            </Form>
        </div>
    )
}
