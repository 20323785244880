import React from 'react'

export default function Header() {
    return (
        <div className='flex py-3 w-full items-center justify-between bg-[#222222] px-5 text-white'>
            <a href='https://www.fitzba.com' target='_blank' rel="noreferrer">
                <img src='/logo-white.png' className='w-[120px] cursor-pointer smsize:w-[100px]' alt='Fitzba.com' />
            </a>
            <div className='smsize:text-[12px] smsize:pr-8 pr-8'>HOLIDAY GIFT GUIDE</div>
        </div>
    )
}
