import React from 'react'

export default function GiftCardsItem({
  item
}) {
  let priceText = ''
  if (item?.min && !item?.max) {
    priceText = `$${item?.min}`
  } else if (!item?.min && item?.max) {
    priceText = `$${item?.max}`
  } else if (item?.min && item?.max) {
    priceText = `$${item?.min} - $${item?.max}`
  }
  return (
    <a href={item?.link} target='_blank' rel="noreferrer" className=' relative w-[300px] border rounded-lg overflow-hidden'>
      <img
        src={item?.image}
        alt={item?.title}
        className='object-cover w-[300px] h-[200px]'
      />
      <div className="absolute text-white bottom-0 left-0 right-0 bg-black bg-opacity-20 p-2 flex justify-between">
        <h2 className=" text-lg font-semibold">{item?.title}</h2>
        {priceText && <h2 className="font-light">{priceText}</h2>}
      </div>
    </a>
  )
}
