/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * The FilterSideBrand.js file defines a component for filtering by brand in a sidebar.
 * 
 * @author Yang Ming
 * @version January 17th, 2024
 */

import React, { useEffect, useState } from 'react'
// import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai'
// import { Input } from 'antd'
// import { VscAccount } from 'react-icons/vsc'
import { debounce } from 'lodash'
import FilterSideSearchBar from './FilterSideSearchBar'
import FilterSideSectionHeader from './FilterSideSectionHeader'
import { setFilterState } from '@reducers/userReducer'
import { useSelector } from 'react-redux'

const DEFAULT_SHOW_NUMBER = 10

/**
 * A component for filtering by brand in a sidebar.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.itemsFilter - The current filter settings.
 * @param {Function} props.dispatch - The Redux dispatch function.
 * @param {Object} props.filterList - The list of available filters.
 * @param {Function} props.setFilterBrand - A function to set the brand filter.
 */
export default function FilterSideBrand({
  itemsFilter,
  dispatch,
  filterList,
  setFilterBrand
}) {

  const [input, setInput] = useState('')
  // const [isOpen, setIsOpen] = useState(false)
  const isOpen = useSelector(state => state.user.filterState.brand)
  const isMobile = useSelector(state => state.content.isMobile)
  const setIsOpen = (value) => {
    dispatch(setFilterState({ type: 'brand', value }))
  }
  const [showNumber, setShowNumber] = useState(filterList?.brands.length)
  const [brands, setBrands] = useState(filterList?.brands)
  const [allCheckbox, setAllCheckbox] = useState(false)

  useEffect(() => {
    setBrands(filterList?.brands)
  }, [filterList?.brands])

  function onClickTitle() {
    defaultSetting()
    setIsOpen(!isOpen)
  }

  function defaultSetting() {
    setInput('')
    setShowNumber(filterList?.brands.length)
    setBrands(filterList?.brands)
  }

  const handleChange = debounce((value) => {
    // console.log('onSearch', value)
    //search function
    const findList = filterList?.brands.filter(item => {
      // startswith or each words startswith if the name is contain splace
      if (!item.name)
        return false
      const words = item.name.indexOf(' ') !== -1 ? item.name.toLowerCase().split(' ') : [item.name.toLowerCase()]
      const inputLower = value.toLowerCase()
      // const result = words.find(item => item.startsWith(inputLower))
      const result = words.find(item => item.indexOf(inputLower) !== -1)
      // console.log('result', result)
      return result
    })
    setShowNumber(filterList?.brands?.length)
    setBrands(findList)
  }, 600)

  const onSearch = (e) => {
    const value = e.target.value
    setInput(value)
    if (!value) {
      defaultSetting()
      return
    }
    handleChange(value)
  }

  function onClickAllCheckbox(checked) {
    console.log('onClickAllCheckbox', checked)
    // const checked = e.target.checked
    if (checked) {
      // select all
      const ids = filterList?.brands.map(item => item.id)
      dispatch(setFilterBrand({ id: ids, checked: true }))
    } else {
      // deselect all
      // const ids = filterList?.brands.map(item => item.id)
      dispatch(setFilterBrand(null))
    }
    setAllCheckbox(checked)
  }

  if (filterList?.brands?.length === 0) return null


  return (
    <div className='filter-item'>
      <FilterSideSectionHeader
        title='Brand'
        isOpen={isOpen}
        onClickTitle={onClickTitle}
        isHighlight={itemsFilter?.brands?.length > 0}
      />
      <FilterSideSearchBar
        input={input}
        isOpen={isOpen}
        onSearch={onSearch}
        onClear={defaultSetting}
        allCheckbox={allCheckbox}
        onSelectAll={() => onClickAllCheckbox(!allCheckbox)}
        onClearAll={() => onClickAllCheckbox(false)}
      />
      <div className={`filter-item-list smsize:gap-2 smsize:text-[16px]`} style={{ display: isOpen ? 'flex' : 'none' }}>
        {brands.slice(0, showNumber).map((brand) => (
          <div key={brand.id} className='filter-checkbox-item'>
            <label>
              <input
                value={brand.id}
                type='checkbox'
                className={`custom-checkbox`}
                checked={itemsFilter?.brands.includes(brand.id)}
                onChange={e => {
                  dispatch(
                    setFilterBrand({
                      id: brand.id,
                      checked: e.target.checked,
                    }),
                  )
                }}
              />
              <span className={`truncate ${isMobile && 'text-[16px]'}`}>{brand.name}</span>
            </label>
          </div>
        ))}
      </div>
      {/* {filterList?.brands.length > DEFAULT_SHOW_NUMBER && */}
      {((input !== '' && brands.length > DEFAULT_SHOW_NUMBER) || (input === '' && filterList?.brands.length > DEFAULT_SHOW_NUMBER)) &&
        <div className='filter-checkbox-item underline' style={{ display: isOpen ? 'flex' : 'none' }}>
          <span onClick={() => setShowNumber(showNumber === DEFAULT_SHOW_NUMBER ? brands.length : DEFAULT_SHOW_NUMBER)}>
            Show {showNumber === DEFAULT_SHOW_NUMBER ? 'More' : 'Less'} Brands ({brands.length})
          </span>
        </div>
      }
    </div>
  )
}
