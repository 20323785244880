/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * Zearch is a functional React component that renders the search functionality,
 * filters, and item listing.
 *
 * @author Yang Ming, Joshua Soke
 * @version May 09, 2023
 */

import React, { useState, useEffect } from 'react'
import './Zearch.css'
import { useSelector } from 'react-redux'
import Filter from './components/Filter'
import { useDispatch } from 'react-redux'
import { useQuery } from '@tanstack/react-query'
import MobileTopFilter from './components/MobileTopFilter'
import StoreList from './StoreList'
import CollectionList from './CollectionList'
import BrandList from './BrandList'
import BrandFilter from './components/BrandFilter'
import StoreFilter from './components/StoreFilter'
import CollectionFilter from './components/CollectionFilter'

export default function Page({
  type = 'store'
}) {
  const dispatch = useDispatch()
  const isMobile = useSelector(state => state.content.isMobile)
  const location = useSelector(state => state.user.location)

  let Content = null
  let FilterContent = null
  switch (type) {
    case 'store':
      Content = <StoreList location={location} dispatch={dispatch} />
      FilterContent = <StoreFilter isMobile={isMobile} />
      break
    case 'collection':
      Content = <CollectionList location={location} dispatch={dispatch} />
      FilterContent = <CollectionFilter isMobile={isMobile} />
      break
    case 'brand':
      Content = <BrandList location={location} dispatch={dispatch} />
      FilterContent = <BrandFilter isMobile={isMobile} />
      break
    default:
      Content = <StoreList location={location} dispatch={dispatch} />
      FilterContent = <StoreFilter isMobile={isMobile} />
  }

  return (
    <div className='w-screen text-left font-lexend md:flex content-wraper hide-scroll'>
      {FilterContent}
      <div className='w-full'>
        {Content}
      </div>
    </div>
  )
}
