import React from 'react'
import './GiftGuide.css'
import { getStoreLink } from '@utils/Helper'
import SeeMoreDetails from './components/SeeMoreDetails'
import Logo from './components/Logo'
import Address from './components/Address'
import PhoneNumber from './components/PhoneNumber'
import Price from './components/Price'
import Header from './components/Header'
import GiftCardButton from './components/GiftCardButton'
import ShopNowButton from './components/ShopNowButton'
import ItemImage from './components/ItemImage'
import ItemTitle from './components/ItemTitle'
import PageNumbers from './components/PageNumbers'
import LeftAndRightBtns from './components/LeftAndRightBtns'
import DropDown from './components/DropDown'

function GiftGuideItemCard({ item, isReverse = false }) {
  if (!item) return null
  return (
    <div
      className={`flex h-[350px] w-full ${isReverse ? 'flex-row-reverse' : ''} smsize:h-[250px]`}
    >
      <div
        className={`flex h-[350px] smsize:w-[200px] w-[50%] smsize:h-[250px] ${isReverse ? 'flex-row-reverse' : ''}`}
      >
        <div className='h-auto w-[100px] bg-[#520a0a] smsize:hidden'> </div>
        <ItemImage
          image={item?.image}
          name={item.name}
          width='100%'
          height='350px'
          extraCss={'w-full smsize:w-[99%] smsize:h-[250px]'}
        />
      </div>
      <div
        className={`flex w-[50%] flex-col justify-start gap-3 px-5 text-left text-[#202020]`}
      >
        <ItemTitle name={item.name} />
        <p
          className='leading-5 line-clamp-5'
          dangerouslySetInnerHTML={{ __html: item?.gift_guide_text }}
        />
        <Price
          salePrice={item?.salePrice}
          priceRange={item?.gift_guide_price_range}
        />
        <SeeMoreDetails item_id={item?._id} />
      </div>
    </div>
  )
}

export default function GiftGuide2a({
  business,
  currentPage,
  totalPage,
  handlePrevious,
  handleNext,
  selectItems,
  index,
  setIndex,
}) {
  const storeLink = getStoreLink(
    business?.shortLink,
    business?.city,
    business?.province,
    business?.name,
    'gift-guide',
  )

  return (
    <div className='relative m-auto flex h-[1280px] w-[768px] flex-col items-center bg-white text-[#202020] shadow-lg smsize:h-full smsize:w-full'>
      <DropDown selectItems={selectItems} index={index} setIndex={setIndex} />
      <LeftAndRightBtns
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        currentPage={currentPage}
        totalPage={totalPage}
      />
      <PageNumbers currentPage={currentPage} totalPage={totalPage} />
      <Header />
      <div className='relative flex flex-col gap-10 px-10 py-5 smsize:p-5'>
        <img
          className='absolute top-[-10px] right-5 z-0 w-[250px] opacity-50 smsize:right-2 smsize:w-[150px]'
          src='/gift-guide/snow-leaf2.png'
          alt='Snow flake'
        />
        <div className='flex gap-5'>
          <Logo
            logo={business?.logo}
            name={business?.name}
            storeLink={storeLink}
          />
          <div className='flex flex-col gap-3 text-left'>
            <h1 className='z-10 w-[400px] text-[45px] font-bold leading-10 text-[#541b1f] smsize:w-full smsize:text-[30px]'>
              {business?.name}
            </h1>
            <Address business={business} />
            <PhoneNumber business={business} />
          </div>
        </div>
        <p className='w-[90%] text-left text-[20px] font-light leading-7 line-clamp-4 smsize:w-full smsize:text-[16px] smsize:leading-5 smsize:line-clamp-5'>
          {business?.shortDesc}
        </p>
      </div>
      <div className='flex w-full items-center justify-start gap-5 px-10'>
        <ShopNowButton
          storeLink={storeLink}
          bgColor='#520a0a'
          borderColor='#520a0a'
          textColor='#fffdf6'
        />
        <GiftCardButton
          giftCardLink={business?.giftCardLink}
          storeLink={storeLink}
          bgColor='#fffdf6'
          borderColor='#520a0a'
          textColor='#520a0a'
        />
      </div>
      <div className='flex py-3'></div>
      {business?.items?.length > 0 && (
        <GiftGuideItemCard item={business?.items[0]} />
      )}
      <div className='flex py-3'></div>
      {business?.items?.length > 1 && (
        <GiftGuideItemCard item={business?.items[1]} isReverse={true} />
      )}
    </div>
  )
}
