/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * user's APIs
 * 
 * @author Yang Ming
 * @version Sept 26, 2023
 */
import { Config } from '../Config'
import axios from 'axios'

const httpClient = axios.create({
    baseURL: Config.apiUrl + Config.apiPrefix,
    timeout: 60 * 1e3,
})

httpClient.interceptors.request.use(config => {
    // Perform logic before sending request
    config.headers['authorization'] = `${Config.apiKey} ` + localStorage.getItem('token')
    return config
})

/**
 * add unwanted items to user's list
 * @param {string} item_id the item's id 
 * @returns  {object} the response object
 */
export async function addUnwantedItems(item_id) {
    try {
        const res = await httpClient.post(`/user/unwantedItems`, {
            id: item_id
        })
        return res.data
    } catch (error) {
        throw error.response
    }
}

/**
 * remove a item from the unwanted items list
 * @param {string} item_id the item's id
 * @returns 
 */
export async function removeUnwantedItems(item_id) {
    try {
        const res = await httpClient.delete(`/user/unwantedItems/${item_id}`)
        return res.data
    } catch (error) {
        throw error.response
    }
}

/**
 * get the unwanted items list
 * @returns unwanted item list
 */
export async function getUnwantedItems() {
    try {
        const res = await httpClient.get(`/user/unwantedItems`)
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function paymentCreditCard(data) {
    try {
        const res = await httpClient.post(`/user/paymentCreditCard`, data)
        // console.log('postMessage', res.data);
        return res.data
    } catch (error) {
        throw error.response
    }
}

// add favorite store
export async function addFavoriteStores(values) {
    try {
        const res = await httpClient.post(`/user/favoriteStores`, values)
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function getFavoriteStores(params) {
    try {
        const res = await httpClient.get(`/user/favoriteStores`, {
            params: params
        })
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function getFavoriteItems(params) {
    try {
        const res = await httpClient.get(`/user/favoriteItems`, {
            params: params
        })
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function actionFavoriteItems(params) {
    try {
        const res = await httpClient.post(`/user/favoriteItems`, params)
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function addFavoriteList(values) {
    try {
        const res = await httpClient.post(`/favorite`, values)
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function updateFavoriteList(id, values) {
    try {
        const res = await httpClient.put(`/favorite/${id}`, values)
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function getFavoriteList(params) {
    try {
        const res = await httpClient.get(`/favorite`, {
            params: params
        })
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function deleteFavoriteList(list_id) {
    try {
        const res = await httpClient.delete(`/favorite/${list_id}`)
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function inviteFriend(values) {
    try {
        const res = await httpClient.post(`/user/invite`, values)
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function getOfferTaskStatus() {
    try {
        const res = await httpClient.get(`/user/offerTaskStatus`)
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function upload(file, photoType) {
    const formData = new FormData()
    formData.append('image', file)
    const res = await httpClient.post(`/user/upload`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        params: {
            photoType: photoType
        }
    })
    return res.data
    // return request(`/upload`, {
    //   method: 'POST',
    //   data: formData,
    //   params: { uid: file.uid, folder: folder },
    //   requestType: 'form',
    // })
}

export async function getPurchases() {
    try {
        const res = await httpClient.get(`/user/purchases`)
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function getOrders() {
    try {
        const res = await httpClient.get(`/user/orders`)
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function promotionRedeem(values) {
    try {
        const res = await httpClient.post(`/user/promotionRedeem`, values)
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function checkRegisterPromoCode(values) {
    try {
        const res = await httpClient.post(`/user/checkRegisterPromoCode`, values)
        return res.data
    } catch (error) {
        throw error.response
    }
}

export async function getAvailablePromotions(params) {
    try {
        console.log('getAvailablePromotions', params)
        const res = await httpClient.get(`/user/getAvailablePromotions`, {
            params: params
        })
        return res.data
    } catch (error) {
        throw error.response
    }
}