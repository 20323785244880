import React, { useEffect } from 'react'
import './GiftGuide.css'
import { useParams, useSearchParams } from 'react-router-dom'
import GiftGuidePage1 from './GiftGuide1'
import GiftGuidePage2 from './GiftGuide2'
import GiftGuide1a from './GiftGuide1a'
import GiftGuide1b from './GiftGuide1b'
import GiftGuide2a from './GiftGuide2a'
import GiftGuide2b from './GiftGuide2b'
import GiftGuide3a from './GiftGuide3a'
import GiftGuide3b from './GiftGuide3b'
import GiftGuide3c from './GiftGuide3c'
import GiftGuide3d from './GiftGuide3d'
import {
  getGiftGuide,
  getGiftGuideStoreList,
} from '@services/api.business.service'
import { Select } from 'antd'

export default function GiftGuideItem() {
  const { shortLink } = useParams()
  console.log('shortLink', shortLink)
  const searchParams = useSearchParams()
  const [data, setData] = React.useState(null)
  const [template, setTemplate] = React.useState('1a')
  const [stores, setStores] = React.useState([])
  const [businessId, setBusinessId] = React.useState(null)

  useEffect(() => {
    async function fetchData() {
      try {
        const result = await getGiftGuideStoreList()
        console.log('GiftGuideItem', result)
        setStores(result?.data)
      } catch (err) {
        console.log(err.data?.message || 'get gift guide item error')
      }
    }
    fetchData()
  }, [])

  const tempList = [
    {
      id: 'page1',
      name: 'page1',
      content: <GiftGuidePage1 />,
    },
    {
      id: 'page2',
      name: 'page2',
      content: <GiftGuidePage2 />,
    },
    {
      id: '1a',
      name: '1a',
      content: <GiftGuide1a business={data} />,
    },
    {
      id: '1b',
      name: '1b',
      content: <GiftGuide1b business={data} />,
    },
    {
      id: '2a',
      name: '2a',
      content: <GiftGuide2a business={data} />,
    },
    {
      id: '2b',
      name: '2b',
      content: <GiftGuide2b business={data} />,
    },
    {
      id: '3a',
      name: '3a',
      content: <GiftGuide3a business={data} />,
    },
    {
      id: '3b',
      name: '3b',
      content: <GiftGuide3b business={data} />,
    },
    {
      id: '3c',
      name: '3c',
      content: <GiftGuide3c business={data} />,
    },
    {
      id: '3d',
      name: '3d',
      content: <GiftGuide3d business={data} />,
    },
  ]

  useEffect(() => {
    if (!shortLink && !businessId) return
    async function fetchData() {
      try {
        const result = await getGiftGuide({
          shortLink: shortLink,
          business_id: businessId,
        })
        console.log('GiftGuideItem', result)
        if (result?.status !== 'success') return
        setData(result?.data)
      } catch (err) {
        console.log(err.data?.message || 'get gift guide item error')
      }
    }
    fetchData()
  }, [shortLink, businessId])

  return (
    <div>
      <Select
        showSearch
        style={{ width: 200 }}
        placeholder='Select a store'
        optionFilterProp='children'
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
          optionA.children
            .toLowerCase()
            .localeCompare(optionB.children.toLowerCase())
        }
        onChange={value => {
          console.log('selectvalue', value)
          setBusinessId(value)
        }}
      >
        {stores?.map(item => (
          <Select.Option key={item._id} value={item._id}>
            {item.name}
          </Select.Option>
        ))}
      </Select>
      <div className='w-full flex items-center justify-center gap-5 p-3 flex-wrap'>
        {tempList.map((item, index) => (
          <button
            key={index}
            className='flex items-center justify-center rounded-full border px-5 py-1'
            onClick={() => setTemplate(item.id)}
          >
            {item.name}
          </button>
        ))}
      </div>
      {data && tempList?.find(item => item.id === template)?.content}
    </div>
  )
}
