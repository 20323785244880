
import React from 'react'
import { Link } from 'react-router-dom'
import './PurchasesEmpty.css'

export default function PurchasesEmpty({
    title
}) {
    return (
        <div className='cart-empty'>
            <h3>You have made no {title}</h3>
            <img src='/images/cart-empty.png' alt='cart-empty' />
            <Link to='/search'>
                <button>Start shopping</button>
            </Link>
        </div>
    )
}
