import React, { useState } from 'react'
import { MdArrowForwardIos } from "react-icons/md"

export default function CollectionFilterSearch({
    isMobile
}) {

    const [input, setInput] = useState('')

    return (
        <div className='filter-item mt-2' style={{
            overflow: 'hidden',
        }}>
            <div className='filter-item-header'>
                <div className='filter-item-header-title'>
                    <h4 className='cursor-pointer text-black'>Collection</h4>
                </div>
            </div>
            <div className='flex flex-row items-center gap-1 font-light text-[16px] text-black'>
                <input
                    className='h-9 border rounded-md bg-white px-[5px] py-[10px] text-base text-black'
                    type='text'
                    name='collection_name'
                    placeholder='Collection name'
                    value={input}
                    onChange={(e) => {
                        setInput(e.target?.value)
                    }}
                    onKeyDown={k => {
                        if (k.key === 'Enter') {
                            
                        }
                    }}
                />
                <button
                    className='flex h-7 w-7 items-center justify-center rounded-md bg-white hover:bg-[#D9D9D9] text-base text-black'
                    onClick={() => {
                        
                    }}
                >
                    <MdArrowForwardIos />
                </button>
            </div>
        </div>
    )
}
