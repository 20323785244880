import React, { useState } from 'react'
import { MdArrowForwardIos } from "react-icons/md"

const typeList = [
    {
        title: 'Buy Now',
        value: 'Buy Now'
    },
    {
        title: 'In Store',
        value: 'In Store'
    },
]

export default function GittCardsFilterSearch({
    setFilter,
    filter
}) {

    const [input, setInput] = useState(filter?.title || '')

    return (
        <div className='filter-item mt-2' style={{
            overflow: 'hidden',
        }}>
            <div className='filter-item-header'>
                <div className='filter-item-header-title'>
                    <h4 className='cursor-pointer text-black'>Store name</h4>
                </div>
            </div>
            <div className='flex flex-row items-center gap-1 font-light text-[16px] text-black'>
                <input
                    className='h-9 border rounded-md bg-white px-[5px] py-[10px] text-base text-black'
                    type='text'
                    name='title'
                    placeholder='Input store name'
                    value={input}
                    onChange={(e) => {
                        setInput(e.target?.value)
                    }}
                    onKeyDown={k => {
                        if (k.key === 'Enter') {
                            setFilter({
                                title: input
                            })
                        }
                    }}
                />
                <button
                    className='flex h-7 w-7 items-center justify-center rounded-md bg-white hover:bg-[#D9D9D9] text-base text-black'
                    onClick={() => {
                        setFilter({
                            title: input
                        })
                    }}
                >
                    <MdArrowForwardIos />
                </button>
            </div>

            <div className='filter-item-header'>
                <div className='filter-item-header-title'>
                    <h4 className='cursor-pointer text-black'>Type</h4>
                </div>
            </div>
            <div className='flex flex-col pl-3 items-center gap-1 font-light text-[16px] text-black'>
                {typeList?.map((item, index) => (
                    <div className='filter-checkbox-item' key={index}>
                        <label>
                            <input
                                value={item.value}
                                type='checkbox'
                                className='custom-checkbox'
                                checked={filter?.types?.includes(item.value)}
                                onChange={e => {
                                    if (e.target.checked) {
                                        setFilter({
                                            types: [...filter?.types, item.value]
                                        })
                                    } else {
                                        setFilter({
                                            types: filter?.types?.filter(type => type !== item.value)
                                        })
                                    }
                                }}
                            />
                            <span className='truncate'>{item.title}</span>
                        </label>
                    </div>
                ))}
            </div>
        </div>
    )
}
