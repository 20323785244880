import React from 'react'
import './GiftGuide.css'
import { getStoreLink } from '@utils/Helper'
import SeeMoreDetails from './components/SeeMoreDetails'
import Logo from './components/Logo'
import Address from './components/Address'
import PhoneNumber from './components/PhoneNumber'
import Price from './components/Price'
import Header from './components/Header'
import GiftCardButton from './components/GiftCardButton'
import ShopNowButton from './components/ShopNowButton'
import ItemImage from './components/ItemImage'
import ItemTitle from './components/ItemTitle'
import PageNumbers from './components/PageNumbers'
import LeftAndRightBtns from './components/LeftAndRightBtns'
import DropDown from './components/DropDown'

// function GiftGuideItem({ item }) {
//   return (
//     <div className='flex w-full items-start justify-start gap-5'>
//       <div className='flex items-stretch justify-center bg-gray-50 smsize:w-1/2'>
//         <div className='h-[250px] w-[300px] smsize:h-[200px] smsize:w-[250px]'>
//           <div className='aspect-w-3 aspect-h-2'>
//             <ItemImage
//               image={item?.image}
//               name={item.name}
//               width='100%'
//               height='100%'
//               extraCss={
//                 'smsize:w-[99%] smsize:h-[200px] max-h-[250px] smsize:max-h-[200px] object-contain'
//               }
//             />
//           </div>
//         </div>
//         <div className='h-auto w-[50px] bg-[#520a0a] smsize:hidden'> </div>
//       </div>
//       <div className='flex w-1/2 flex-col justify-start gap-3 pr-5 text-left text-[#202020] smsize:w-full'>
//         <ItemTitle name={item.name} />
//         <Price
//           salePrice={item?.salePrice}
//           priceRange={item?.gift_guide_price_range}
//         />
//         <p
//           className='leading-5 line-clamp-5'
//           dangerouslySetInnerHTML={{ __html: item?.gift_guide_text }}
//         />
//         <SeeMoreDetails item_id={item?._id} />
//       </div>
//     </div>
//   )
// }

function GiftGuideItem({ item }) {
  return (
    <div className='flex w-full items-start justify-start gap-5'>
      <div className='flex items-stretch smsize:w-full'>
        <div className='flex h-[260px] w-[300px] items-start justify-start smsize:w-[200px]'>
          <ItemImage
            image={item?.image}
            name={item.name}
            width='100%'
            height='260px'
            extraCss={
              'max-h-[260px] smsize:w-[99%] smsize:h-[200px]'
            }
          />
        </div>
        <div className='w-[50px] bg-[#520a0a] smsize:hidden'> </div>
      </div>
      <div className='flex w-[400px] flex-col justify-start gap-3 pr-5 text-left text-[#202020] smsize:w-full'>
        <ItemTitle name={item.name} />
        <Price
          salePrice={item?.salePrice}
          priceRange={item?.gift_guide_price_range}
        />
        <p
          className='leading-5 line-clamp-5'
          dangerouslySetInnerHTML={{ __html: item?.gift_guide_text }}
        />
        <SeeMoreDetails item_id={item?._id} />
      </div>
    </div>
  )
}

export default function GiftGuide3c({
  business,
  currentPage,
  totalPage,
  handlePrevious,
  handleNext,
  selectItems,
  index,
  setIndex,
}) {
  const storeLink = getStoreLink(
    business?.shortLink,
    business?.city,
    business?.province,
    business?.name,
    'gift-guide',
  )
  return (
    <div className='relative m-auto flex h-[1280px] w-[768px] flex-col items-center bg-white text-[#202020] shadow-lg smsize:h-full smsize:w-full'>
      <DropDown selectItems={selectItems} index={index} setIndex={setIndex} />
      <LeftAndRightBtns
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        currentPage={currentPage}
        totalPage={totalPage}
      />
      <PageNumbers currentPage={currentPage} totalPage={totalPage} />
      <Header />
      <div className='relative flex flex-col gap-3 p-5 pb-5 smsize:pb-5'>
        <img
          className='absolute top-5 right-10 w-[150px] opacity-50 smsize:right-0 smsize:w-[100px]'
          src='/gift-guide/snow-flake2.png'
          alt='Snow flake'
        />
        <div className='flex justify-between gap-3 smsize:flex-col'>
          <div className='flex w-full flex-1 flex-col justify-between gap-5'>
            <div className='flex items-start gap-3'>
              <Logo
                logo={business?.logo}
                name={business?.name}
                storeLink={storeLink}
              />
              <div className='flex flex-col gap-3'>
                <h1 className='z-10 text-left text-[40px] font-bold leading-10 text-[#541b1f] smsize:w-full smsize:text-[30px]'>
                  {business?.name}
                </h1>
                <div className='flex flex-col gap-1 text-left'>
                  <Address business={business} />
                  <PhoneNumber business={business} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='flex-1 text-left text-[20px] font-light leading-7 line-clamp-4 smsize:w-full smsize:text-[16px] smsize:leading-5 smsize:line-clamp-5'>
          {business?.shortDesc}
        </div>
        <div className='flex w-full items-center justify-start gap-3'>
          <GiftCardButton
            giftCardLink={business?.giftCardLink}
            storeLink={storeLink}
            bgColor='#520a0a'
            borderColor='#fffdf6'
            textColor='#fffdf6'
            extraCss={'px-3'}
          />
          <ShopNowButton
            storeLink={storeLink}
            bgColor='#fffdf6'
            borderColor='#520a0a'
            textColor='#520a0a'
            extraCss={'px-3'}
          />
        </div>
      </div>
      {/* <div className='flex py-3'></div> */}
      <div className='flex w-full flex-col justify-start gap-5 py-3'>
        {business?.items?.map((item, index) => {
          return <GiftGuideItem key={index} item={item} />
        })}
      </div>
    </div>
  )
}
