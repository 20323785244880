import React from 'react'

export default function ShopNowButton({
    storeLink,
    bgColor,
    borderColor,
    textColor,
    extraCss
}) {
    return (
        <a
            href={storeLink}
            target='_blank'
            rel='noreferrer'
            className={`whitespace-nowrap flex items-center justify-center rounded-full border-[2px] border-[${borderColor}] bg-[${bgColor}] py-1 px-7 uppercase text-[${textColor}] ${extraCss}`}
        >
            shop now
        </a>
    )
}
