import React, { useEffect } from 'react'
import { ssoLoginGoogle } from '@services/api.service'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setUserinfo } from '@reducers/userReducer'
import { useGoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google'
import { Config } from '@Config'
import { isRunningInWebView } from '@utils/Helper'

// function isWebView() {
//     const userAgent = navigator.userAgent || navigator.vendor || window.opera

//     // iOS WebView detection
//     const isIOSWebView = /iPhone|iPod|iPad/i.test(userAgent) &&
//         !window.MSStream &&
//         (!/Safari/.test(userAgent) || /CriOS/.test(userAgent))

//     // Android WebView detection
//     const isAndroidWebView = /Android/.test(userAgent) && /wv/.test(userAgent)
//     return isIOSWebView || isAndroidWebView
// }

export default function SSOLoginGoogle({
    inviteUser
}) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const backToRoute = useSelector(state => state.temp.backToRoute) || '/'
    const [webView, setWebView] = React.useState(false)

    useEffect(() => {
        setTimeout(() => {
            console.log('isWebView', isRunningInWebView())
            setWebView(isRunningInWebView())
        }, 500)
    }, [])

    const handleOnSuccess = async (response) => {
        console.log('handleOnSuccess', response)
        try {
            // const token = response.credential // Example: Google ID Token
            // const redirectUrl = `http://localhost:4001/api/googleAuth?token=${token}`
            // window.location.href = redirectUrl // Redirect with token
            // const access_token = response.access_token
            const res = await ssoLoginGoogle({
                ...response,
                inviteCode: inviteUser?.inviteCode
            })
            console.log('handleOnSuccess', res)
            dispatch(setUserinfo(res.data))
            localStorage.removeItem('tmp_id')
            navigate(backToRoute, { replace: true })
        } catch (err) {
            console.log('handleOnSuccess error:', err)
        }
    }

    const handleOnError = (response) => {
        console.log('handleOnError', response)
    }

    const login = useGoogleLogin({
        onSuccess: handleOnSuccess,
        onError: handleOnError,
        useOneTap: true
    })

    useGoogleOneTapLogin({
        onSuccess: handleOnSuccess,
        onError: handleOnError,
    })

    const handleGoogleLogin = () => {
        const redirect_uri = window.location.origin
        // const redirect_uri = 'http://localhost:3000/googleAuth'
        console.log('redirect_uri', redirect_uri)
        // storagerelay%3A%2F%2Fhttp%2Flocalhost%3A3000%3Fid%3Dauth122090
        const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?gsiwebsdk=3&client_id=${Config.googleSSOClientId}&scope=openid%20profile%20email&redirect_uri=${redirect_uri}&prompt=select_account&response_type=token&include_granted_scopes=true&enable_granular_consent=true&service=lso&o2v=2&ddm=1&flowName=GeneralOAuthFlow`

        console.log('googleAuthUrl', googleAuthUrl)
        window.location.href = googleAuthUrl
    }

    const handleLoginWithGoogle = () => {
        if (webView) {
            console.log('webView', webView)
            handleGoogleLogin()
        } else {
            console.log('not webView', webView)
            login()
        }
    }

    return (
        webView ? (
            <button onClick={handleGoogleLogin} className='h-[43px] border rounded-full border-[#747775] w-full flex items-center justify-center gap-3 text-[15px] font-medium text-center' >
                <img src='/images/Google.png' alt='Sign up with Google' />Sign in with Google
            </button>
        ) : (
            <button onClick={login} className='h-[43px] border rounded-full border-[#747775] w-full flex items-center justify-center gap-3 text-[15px] font-medium text-center'>
                <img src='/images/Google.png' alt='Sign up with Google' />Sign in with Google
            </button>
        )
    )
}

        // <GoogleOAuthProvider clientId={Config.googleSSOClientId}>
        //      <GoogleLogin
        //         render={(renderProps) => {
        //             console.log('renderProps', renderProps)
        //             return <button className='h-[43px] border rounded-full border-[#747775] w-full flex items-center justify-center gap-3 text-[15px] font-medium text-center'>
        //                 <img src='/images/Google.png' alt='Sign up with Google' />Sign up with Google
        //             </button>
        //         }}
        //         onSuccess={handleOnSuccess}
        //         onError={handleOnError}
        //         cookiePolicy="single_host_origin"
        //     />
        // </GoogleOAuthProvider>
