import React from 'react'
import { formatCurrency2 } from '@utils/Helper'

export default function Price({
    salePrice,
    priceRange,
    isRight
}) {
    const isBasedOnSizing = priceRange && priceRange === 'Based on Sizing'
    return (
        <div className={`flex items-center gap-1 ${isRight ? 'justify-end' : 'justify-start'}`}>
            <span className='text-[30px] font-light smsize:text-[25px]'>$</span>
            <strong className={`${isBasedOnSizing ? 'text-[25px] smsize:text-[18px]' : 'text-[40px] smsize:text-[22px]'} font-normal whitespace-nowrap `}>
                {priceRange || formatCurrency2(salePrice)}
            </strong>
        </div>
    )
}
